<template>
  <vs-sidebar
    click-not-close
    position-right
    parent="body"
    default-index="1"
    color="primary"
    class="vs-sidebar__wrapper"
    spacer
    v-model="isActive"
  >
    <div class="mt-6 flex items-center justify-between px-6">
      <h4>{{ titleType }} RETAILER</h4>
      <feather-icon
        icon="XIcon"
        @click.stop="isActive = false"
        class="cursor-pointer"
      />
    </div>

    <vs-divider class="mb-0" />

    <component
      :is="scrollbarTag"
      :settings="settings"
      class="vs-sidebar__inner"
    >
      <!-- NAME -->
      <div>
        <vs-label text="Name" />
        <vs-input
          v-model="dataName"
          class="w-full"
          name="name"
          v-validate="'required'"
        />
        <span class="text-danger text-sm" v-show="errors.has('name')">
          {{ errors.first('name') }}
        </span>
      </div>

      <!-- COUNTRY -->
      <div class="mt-4">
        <vs-label text="Country" />
        <v-select
          :options="countryList"
          :clearable="false"
          v-model="dataCountry"
          name="country"
          v-validate="'required'"
        />
        <span class="text-danger text-sm" v-show="errors.has('country')">
          {{ errors.first('country') }}
        </span>
      </div>

      <!-- ECOMMERCE ADDRESS -->
      <div class="mt-4">
        <vs-label text="E-commerce address" />
        <vs-input v-model="dataUrl" class="w-full" name="url" />
      </div>

      <!-- DESCRIPTION -->
      <div class="mt-4">
        <vs-label text="Description" />
        <vs-textarea v-model="dataDescription" name="description" />
      </div>

      <!-- BUTTONS -->
      <sidebar-buttons
        :valid="isFormValid"
        @click="handleSubmit"
        @cancel="handleClose"
      />
    </component>
  </vs-sidebar>
</template>

<script>
import vSelect from 'vue-select';
import { mapActions } from 'vuex';

import { RetailerAction } from '@/store/actionTypes';

import { regionList } from '@/enums/Region';
import { countryList } from '@/enums/Country';

import sidebarMixin from '@/mixins/sidebarMixin';

import '@/assets/scss/app/pages/sidebar.scss';

export default {
  mixins: [sidebarMixin],
  components: {
    vSelect,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      dataId: null,
      dataName: '',
      dataCountry: null,
      dataUrl: '',
      dataDescription: '',

      regionList,
      countryList,
    };
  },
  watch: {
    isSidebarActive(val) {
      if (!val) return;

      if (Object.entries(this.data).length === 0) {
        this.initValues();
        this.$validator.reset();
      } else {
        const { id, name, country, url, description } = JSON.parse(
          JSON.stringify(this.data)
        );

        this.dataId = id;
        this.dataName = name;
        this.dataCountry = this.getCountryByValue(country);
        this.dataUrl = url;
        this.dataDescription = description;

        this.initValues();
      }
    },
  },
  computed: {
    isFormValid() {
      return !this.errors.any() && this.dataName && this.dataCountry;
    },
  },
  methods: {
    ...mapActions('retailer', [RetailerAction.add, RetailerAction.update]),

    initValues() {
      if (this.data.id) return;

      this.dataId = null;
      this.dataName = '';
      this.dataCountry = null;
      this.dataUrl = '';
      this.dataDescription = '';
    },
    async handleSubmit() {
      const isValid = await this.$validator.validateAll();

      if (!isValid) return;

      const obj = {
        id: this.dataId,
        name: this.dataName,
        country: this.dataCountry.value,
        region: this.dataCountry.region,
        url: this.dataUrl,
        description: this.dataDescription,
      };

      this.$vs.loading();

      if (this.shouldUpdate) {
        try {
          await this.updateRetailer(obj);

          this.notifySuccess(
            'Retailer updated',
            'The retailer has been successfully updated.'
          );
        } catch (error) {
          this.notifyError(error, 'Problem with updating retailers.');
        }
      } else {
        delete obj.id;

        try {
          await this.addRetailer(obj);

          this.notifySuccess(
            'Retailer added',
            'The retailer has been successfully added.'
          );
        } catch (error) {
          this.notifyError(error, 'Problem with adding retailers.');
        }
      }

      this.$vs.loading.close();
      this.$emit('close-sidebar');
      this.initValues();
    },
    getCountryByValue(val) {
      return this.countryList.find((el) => el.value === val);
    },
    getRegionByValue(val) {
      return this.regionList.find((el) => el.value === val);
    },
  },
};
</script>
